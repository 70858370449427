/* Base page container */
.pageContainer {
  min-height: 100vh;
  background-color: #f9fafb;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

/* Action Card */
.actionCard {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  margin: 0;
}

.actionCardLeft {
  display: flex;
  align-items: center;
}

.actionCardRight {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Zoom Controls */
.zoomControls {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 100;
  align-items: center;
  background-color: white;
  border-radius: 24px;
  padding: 6px 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.zoomBtn {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #2196f3;
  color: white;
  border: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.zoomBtn:hover {
  background-color: #0d8aed;
  transform: translateY(-2px);
}

.zoomBtn:active {
  transform: translateY(0);
}

.zoomBtn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.zoomLevel {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  min-width: 44px;
  text-align: center;
}

/* Invoice viewer container */
.invoiceViewerContainer {
  width: 100%;
  height: calc(100vh - 80px);
  margin-top: 70px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 20px 25px;
  background-color: #f3f4f6;
}

/* Invoice Container */
.invoiceContainer {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: visible;
  position: relative;
  width: 210mm;
  min-height: 297mm;
  display: flex;
  flex-direction: column;
  transform-origin: top center;
  transition: transform 0.2s ease;
}

.invoiceContent {
  background-color: white;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Ensure the banner doesn't overflow */
}

/* Status Banner - Redesigned to match the reference image */
.statusBanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}

.statusBanner::before {
  content: attr(data-status);
  position: absolute;
  width: 200px;
  top: 35px;
  left: -50px;
  transform: rotate(-45deg);
  transform-origin: center;
  text-align: center;
  padding: 8px 0;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.05em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Status colors */
.statusBanner[data-status="pending"]::before {
  background-color: #f59e0b; /* Amber/Orange for pending */
}

.statusBanner[data-status="unpaid"]::before {
  background-color: #ef4444; /* Red for unpaid */
}

.statusBanner[data-status="paid"]::before {
  background-color: #10b981; /* Green for paid */
}

.statusBanner[data-status="partially paid"]::before {
  background-color: #3b82f6; /* Blue for partially paid */
}

.statusBanner[data-status="cancelled"]::before {
  background-color: #6b7280; /* Gray for cancelled */
}

.statusBanner[data-status="overdue"]::before {
  background-color: #b91c1c; /* Dark red for overdue */
}

/* Value Styling */
.creditAmount {
  color: #28a745 !important;
  font-weight: 500;
}

.debitAmount {
  color: #dc3545 !important;
  font-weight: 500;
}

/* Invoice Header */
.invoiceHeader {
  background-color: #2196f3;
  padding: 1.5rem 2rem;
  color: white;
}

.invoiceHeaderContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Header title styling */
.headerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.invoiceTitleInHeader {
  color: white;
  font-size: 28px;
  font-weight: 600;
  margin-top: -20px;
  margin-left: 60%;
}

/* Logo Styling */
.logoContainer {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-left: 60%;
}

.companyLogo {
  max-width: 150px;
  max-height: 80px;
  object-fit: contain;
}

.headerDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detailRow {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.label {
  color: white;
  font-size: 0.875rem;
  min-width: 120px;
  font-weight: 500;
}

.value {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 400;
}

/* Override for grid values to ensure they're visible */
.gridRow .value {
  color: #1f2937;
}

.gridRow .value.right {
  text-align: right;
  color: #1f2937;
}

/* Invoice Body */
.invoiceBody {
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.addressSection {
  margin-bottom: 2rem;
}

.addressLayout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  align-items: start;
}

.sectionTitle {
  color: #6b7280;
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.addressContent {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #111827;
}

.totalSection {
  text-align: right;
}

.totalAmount {
  color: #2196f3;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 0.25rem;
}

/* Items Grid */
.itemsGrid {
  width: 100%;
  margin-top: 2rem;
  border-top: 2px solid #2196f3;
  padding-top: 1rem;
  flex: 1;
}

.gridHeader {
  display: grid;
  grid-template-columns: 1fr 150px 100px 150px;
  gap: 1rem;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}

.gridRow {
  display: grid;
  grid-template-columns: 1fr 150px 100px 150px;
  gap: 1rem;
  padding: 0.5rem 0;
  align-items: center;
}

.columnHeader {
  color: #2196f3;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
}

.columnHeader.right {
  text-align: right;
}

.value.right {
  text-align: right;
}

/* Calculations */
.calculations {
  margin-top: 2rem;
  padding-right: 0;
}

.calculationsGrid {
  display: grid;
  grid-template-columns: 1fr 150px;
  gap: 0.5rem;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
}

.calculationsRow {
  display: contents;
}

.calcLabel {
  color: #2196f3;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: right;
  text-transform: uppercase;
}

.calcValue {
  color: #1f2937;
  font-size: 0.875rem;
  text-align: right;
}

.divider {
  grid-column: 1 / -1;
  height: 1px;
  background-color: #e5e7eb;
  margin: 0.75rem 0;
}

/* Enhanced styling for the balance row */
.calculationsRow.balance {
  font-weight: bold;
  padding-top: 8px;
  margin-top: 4px;
}

.totalRow {
  display: grid;
  grid-template-columns: 1fr 150px;
  gap: 0.5rem;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e7eb;
}

.totalLabel {
  color: #2196f3;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: right;
  text-transform: uppercase;
}

.totalValue {
  color: #1f2937;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: right;
}

/* Footer */
.footer {
  margin-top: auto;
  padding: 2rem;
  background-color: #f3f4f6;
  border-top: 1px solid #e5e7eb;
}

.bankDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detailsTitle {
  color: #1f2937;
  font-size: 0.875rem;
  font-weight: 500;
}

.detailsText {
  color: #4b5563;
  font-size: 0.875rem;
}

/* Payment Details */
.paymentDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Signature Section */
.signatureSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1rem;
}

.signatureBox {
  min-width: 200px;
}

.centeredText {
  margin-top: 1rem;
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #6b7280;
}

/* Small screen adjustments */
@media screen and (max-width: 768px) {
  .actionCard {
    padding: 0.75rem 1.25rem;
  }

  .actionCardRight {
    gap: 0.5rem;
  }

  .zoomControls {
    bottom: 10px;
    right: 10px;
  }

  .invoiceViewerContainer {
    padding: 10px;
    height: calc(100vh - 70px);
    margin-top: 70px;
  }
}

/* Mobile adjustments */
@media screen and (max-width: 480px) {
  .actionCardRight {
    width: 100%;
    justify-content: center;
  }

  .actionCard {
    flex-direction: column;
    padding: 0.5rem;
    height: auto;
  }

  .invoiceViewerContainer {
    margin-top: 100px;
    height: calc(100vh - 100px);
  }
}

/* Print styles */
@media print {
  .actionCard,
  .zoomControls {
    display: none;
  }

  .pageContainer {
    padding: 0;
    background: white;
  }

  .invoiceContainer {
    transform: none !important;
    width: 100%;
    height: auto;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
  }

  .invoiceViewerContainer {
    padding: 0;
    margin: 0;
    height: auto;
    overflow: visible;
  }

  .invoiceHeader {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .footer {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    background-color: #f3f4f6 !important;
  }

  .companyLogo {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .statusBanner::before {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  @page {
    size: A4;
    margin: 0;
  }
}

