.pageContainer {
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

/* Action Bar (Back Link + Download Button) */
.actionBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.backLink {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #374151;
  font-weight: 500;
  gap: 0.5rem;
  cursor: pointer;
}

.backLink:hover {
  text-decoration: underline;
}

.downloadButton {
  background-color: #0ea5e9 !important; /* Blue color */
  border: none !important;
  color: #fff !important;
}

/* Invoice Wrapper */
.invoiceWrapper {
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* Header */
.invoiceHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 3px solid #0ea5e9; /* Blue underline */
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.invoiceTitle {
  font-size: 1.75rem;
  font-weight: bold;
  color: #0ea5e9; /* Blue title */
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.invoiceInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.infoLabel {
  font-weight: bold;
  color: #4a4a4a;
}

/* Company & Customer */
.companyCustomerSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.sectionHeading {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #4a4a4a;
}

.companyBlock, .customerBlock {
  width: 48%;
  margin-bottom: 1rem;
}

.companyName {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.25rem;
}

.customerName {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.25rem;
}

/* Meter Details */
.meterDetails {
  margin-bottom: 2rem;
}

.meterTable {
  width: 100%;
  border-collapse: collapse;
}

.meterTable th {
  background-color: #0ea5e9;
  color: #fff;
  padding: 0.75rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.meterTable td {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  font-size: 0.9rem;
  color: #333;
}

/* Consumption */
.consumptionBlock {
  margin-bottom: 2rem;
  background-color: #fafafa;
  padding: 1rem;
  border-left: 4px solid #0ea5e9;
}

.consumptionBlock p {
  margin: 0.25rem 0;
}

/* Footer / Payment Details */
.footerSection {
  background-color: #f3f3f3;
  padding: 1rem;
  border-top: 3px solid #0ea5e9;
}

.footerSection p {
  margin: 0.25rem 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Print Styles */
@media print {
  .actionBar {
    display: none !important;
  }
  .pageContainer {
    background-color: #fff !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .invoiceWrapper {
    box-shadow: none;
    padding: 1rem;
    margin: 0;
  }
  @page {
    margin: 0;
    size: A4;
  }
}
